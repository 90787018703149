<template>
    <div class="RoleEdit">
        <el-card shadow="never" style="margin-top: 8px">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="form" :model="deptGroupRole" label-width="80px" :rules="rules" size="small">
                <el-form-item label="机构组" prop="source">
                    <el-select v-model="deptGroupRole.source" :loading="loadingDeptGroupFlag">
                        <el-option
                            v-for="group in meta.groups"
                            :value="group.code"
                            :key="group.code"
                            :label="group.name"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="角色名称" prop="name">
                    <el-input v-model="deptGroupRole.name" />
                </el-form-item>
                <el-form-item label="角色类型">
                    <div>{{ form.type ? '业务型角色' : '管理型角色' }}</div>
                </el-form-item>
                <el-form-item label="归属类型">
                    <div>{{ form.deptType | deptType }}</div>
                </el-form-item>
                <el-form-item label="权重" prop="weight">
                    <el-input v-model.number="deptGroupRole.weight" />
                </el-form-item>
                <ef-remark v-model="deptGroupRole.remark" />
                <el-form-item>
                    <el-button type="primary" @click="handleSave" size="small">保存</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import UrlUtils from '../../../../js/UrlUtils';
import EfRemark from 'components/EfRemark';

export default {
    name: 'RoleEdit',
    components: { EfRemark },
    props: ['form'],
    data() {
        return {
            deptGroupRole: {},
            url: '/system/deptGroupRole/patch_c/',
            rules: {
                source: [{ required: true, message: '机构组必填', trigger: 'change' }],
                name: [{ required: true, max: 200, message: '请输入名称', trigger: 'blur' }],
                remark: [{ required: true, max: 200, message: '请输入备注,长度不超过200', trigger: 'blur' }],
                weight: [
                    {
                        required: true,
                        message: '权重必填',
                        trigger: 'blur',
                    },
                ],
            },
            meta: {
                groups: [],
                deptCodes: [],
            },
            loadingDeptGroupFlag: true,
        };
    },
    mounted() {
        UrlUtils.DeptGroup(this, (rst) => {
            this.meta.groups = rst || [];
            this.loadingDeptGroupFlag = false;
        });
        this.deptGroupRole = {
            name: this.form.name,
            source: this.form.source,
            weight: this.form.weight,
            remark: this.form.remark,
            deptType: this.form.deptType,
            type: this.form.type,
        };
    },
    methods: {
        handleSave() {
            const _this = this;
            this.$refs.form.validate((valid) => {
                if (!valid) return;
                UrlUtils.PatchRemote(this, this.url + this.form.code, this.deptGroupRole, null, (data) => {
                    _this.$message.success('保存成功');
                    this.goBackAndReload();
                });
            });
        },
    },
};
</script>
<style scoped>
.el-input {
    width: auto;
}
.el-textarea {
    width: auto;
}
</style>
